import { SiGooglemaps } from 'react-icons/si'
import BgStyle from '../images/contact-pattern.jpg'

const Contact = () => {
  const bgStyle = {
    backgroundImage: `url(${BgStyle})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat', // Jeśli chcesz, aby tło było powtarzane, zostaw 'repeat'; jeśli nie, zmień na 'no-repeat'
    height: '100%', // Wysokość slidera
    width: '100%', // Szerokość slidera
  }

  return (
    <section
      id="contact"
      className="bg-white shadow-md px-5 py-10 sm:p-10 lg:p-20"
    >
      <div className="container-normal">
        <div
          className="grid grid-cols-1 xl:grid-cols-[4fr_2fr] gap-10 p-6 sm:p-8 xl:p-20 shadow-lg w-full"
          style={bgStyle}
        >
          {/* Lewa strona */}
          <div>
            <h3 className="text-red-500 text-sm uppercase font-bold mb-3">
              our location
            </h3>
            <h2 className="text-black text-2xl sm:text-3xl xl:text-5xl leading-[2rem] xl:leading-[3.5rem] font-bold mb-6">
              Our company is based <br />
              in Konin (Poland)
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="flex items-start shadow-lg py-7 px-4 bg-white">
                <div className="flex justify-start">
                  <div className="pr-4">
                    <SiGooglemaps className="text-3xl text-red-600 mt-[8px]" />
                  </div>
                  <div>
                    <h3 className="text-black font-semibold text-xl">
                      Address
                    </h3>
                    <p className="text-black">
                      Kraśnica-Kolonia 73, <br />
                      62-590 Golina Poland
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-start shadow-lg py-7 px-4 bg-white">
                <div className="flex justify-start">
                  <div className="pr-4">
                    <SiGooglemaps className="text-3xl text-red-600 mt-[8px]" />
                  </div>
                  <div>
                    <h3 className="text-black font-semibold text-xl">
                      Production Contact
                    </h3>
                    <p className="text-black">
                      email: production@exspray.eu <br />
                      Phone number: +48 453 433 883
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex items-start shadow-lg py-7 px-4 bg-white">
                <div className="flex justify-start">
                  <div className="pr-4">
                    <SiGooglemaps className="text-3xl text-red-600 mt-[8px]" />
                  </div>
                  <div>
                    <h3 className="text-black font-semibold text-xl">
                      CEO Contact
                    </h3>
                    <p className="text-black">email: exspray@exspray.eu</p>
                  </div>
                </div>
              </div>

              <div className="flex items-start shadow-lg py-7 px-4 bg-white">
                <div className="flex justify-start">
                  <div className="pr-4">
                    <SiGooglemaps className="text-3xl text-red-600 mt-[8px]" />
                  </div>
                  <div>
                    <h3 className="text-black font-semibold text-xl">
                      Sales Contact
                    </h3>
                    <p className="text-black">
                      email: sales@exspray.eu <br />
                      Phone number: +48 453 444 334
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Prawa strona */}
          <div className="bg-red-600 p-8 shadow-lg flex flex-col justify-center items-center w-full">
            <h3 className="text-white text-4xl font-bold mb-6 text-center">
              Write to us
            </h3>
            <form className="space-y-4 w-5/6">
              <div>
                <label className="hidden">Imię i nazwisko</label>
                <input
                  type="text"
                  className="w-full py-3 px-6 rounded-full bg-white text-black text-left"
                  placeholder="Imię i nazwisko"
                />
              </div>
              <div>
                <label className="hidden">Telefon</label>
                <input
                  type="tel"
                  className="w-full py-3 px-6 rounded-full bg-white text-black text-left"
                  placeholder="Telefon"
                />
              </div>
              <div>
                <label className="hidden">Wiadomość</label>
                <textarea
                  className="w-full py-3 px-6 rounded-lg bg-white text-black text-left"
                  placeholder="Wiadomość"
                ></textarea>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full p-3 bg-black text-white rounded-full"
                  aria-label="Wyślij wiadomość"
                >
                  Send Mail{' '}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact

import React from 'react'
import Header from './components/Header'
import Hero from './components/Hero'
import ProductInfo from './components/ProductInfo'
import FeaturesWithImageSection from './components/FeaturesWithImageSection'
import ProductSlider from './components/ProductSlider'
import ContactSeparator from './components/ContactSeparator'
import Contact from './components/Contact'
import Footer from './components/Footer'
import WeCooperate from './components/WeCooperate'

function App() {
  return (
    <>
      <Header />
      <main>
        <Hero />
        <ProductInfo />
        <FeaturesWithImageSection />
        <ProductSlider />
        <WeCooperate />
        <ContactSeparator />
        <Contact />
      </main>
      <Footer />
    </>
  )
}

export default App

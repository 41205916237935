import { FaPhoneAlt, FaBars } from 'react-icons/fa'
import logo from '../images/logo.png'

const Header = () => {
  return (
    <header id="start" className="bg-white bg-opacity-5 absolute w-full z-10">
      <div className="container-normal justify-between items-center relative z-10 h-20 lg:h-[96px]">
        <div className="logo flex items-center">
          <a href="/">
            <img
              src={logo}
              alt="logo"
              className="w-[140px] xl:w-[170px] h-auto object-contain"
            />
          </a>
        </div>

        {/* Hamburger Icon for Small Screens */}
        <input type="checkbox" id="menu-toggle" className="peer hidden" />
        <label
          htmlFor="menu-toggle"
          className="cursor-pointer lg:hidden block text-white text-3xl"
        >
          <FaBars />
        </label>

        {/* Navigation for Larger Screens and Small Screens (Peer Checked) */}
        <nav className="navigation lg:flex hidden flex-col lg:flex-row justify-center items-center">
          <ul className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-6 text-lg font-semibold text-white">
            <li className="hover:text-gray-200 active:text-red-600">
              <a href="#start">Start</a>
            </li>
            <li className="hover:text-gray-200 active:text-red-600">
              <a href="#about-us">About us</a>
            </li>
            <li className="hover:text-gray-200 active:text-red-600">
              <a href="#our-products">Our products</a>
            </li>
            <li className="hover:text-gray-200 active:text-red-600">
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </nav>

        {/* Mobile Menu */}
        <nav className="navigation peer-checked:flex hidden lg:hidden flex-col items-center absolute top-20 left-0 bg-white w-full z-20">
          <ul className="flex w-full flex-col space-y-5 text-lg font-semibold text-black p-7">
            <li className="hover:text-gray-600 active:text-red-600">
              <a href="#start">Start</a>
            </li>
            <li className="hover:text-gray-600 active:text-red-600">
              <a href="#about-us">About us</a>
            </li>
            <li className="hover:text-gray-600 active:text-red-600">
              <a href="#our-products">Our products</a>
            </li>
            <li className="hover:text-gray-600 active:text-red-600">
              <a href="#contact">Contact</a>
            </li>
          </ul>
        </nav>

        {/* Contact Section (Hidden on Small Screens) */}
        <div className="contact hidden lg:flex justify-center items-center bg-red-600 p-4 rounded-l-full">
          <FaPhoneAlt className="bg-white rounded-full p-3 text-5xl text-red-600" />
          <div className="font-bold px-2 py-2 text-white h-16 ml-2">
            <p className="text-[17px]">Telephone</p>
            <p className="text-[15px]">+48 453 444 334</p>
          </div>
        </div>
      </div>

      <div className="redblock bg-red-600 absolute top-0 right-0 lg:w-[185px] xl:w-[210px] 2xl:w-[300px] h-[96px] z-0"></div>
    </header>
  )
}

export default Header

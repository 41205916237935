import React from 'react'

// Kropki będą krótsze i bez flex-grow
const dotStyle = {
  width: '4px', // Zmniejszona szerokość kropek
  height: '2px',
  backgroundColor: '#d10000', // Kolor kropek
  borderRadius: '2px',
  marginRight: '1px', // Mniejszy odstęp między kropkami
  flexGrow: 1,
}

const createDots = (count) => {
  return Array.from({ length: count }, (_, index) => (
    <span key={index} style={dotStyle}></span>
  ))
}

const SectionTitleWithDots = ({ titleText, color }) => {
  return (
    <div className="flex justify-center items-center flex-col w-screen">
      <div
        className="
          section-title-with-dots 
          flex 
          items-center 
          justify-between 
          w-full 
          mt-10
          sm:mt-8
          mb-10
          sm:mb-8
          lg:mb-10
          xl:mb-12
          text-center
      "
      >
        {/* Dots on the left */}
        <div className="flex-grow hidden md:flex items-center justify-end pr-4">
          {createDots(20)} {/* Więcej kropek po lewej */}
        </div>

        {/* Title */}
        <h2
          className={`
            w-full
            md:max-w-max
            text-center
            text-[3rem]
            sm:text-[2.5rem]
            md:text-[3rem]
            lg:text-[4rem]
            xl:text-[5rem]
            2xl:text-[7rem]
            pb-4
            sm:pb-0
            px-4
            uppercase
            font-bold
            tracking-widest
            leading-[3rem]
            sm:leading-[4rem]
            md:leading-[5rem]
            lg:leading-[6rem]
            xl:leading-[7rem]
            2xl:leading-[8rem]
          `}
          style={{ color: color }} // Dynamiczny kolor
        >
          {titleText}
        </h2>

        {/* Dots on the right */}
        <div className="flex-grow hidden md:flex items-center justify-start pl-4">
          {createDots(20)} {/* Więcej kropek po prawej */}
        </div>
      </div>
    </div>
  )
}

export default SectionTitleWithDots

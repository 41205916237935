import React from 'react'
import bannerImage from '../images/contact-separator.jpg'

const ContactSeparator = () => {
  // eslint-disable-next-line no-unused-vars
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1024)

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024)
    }

    window.addEventListener('resize', handleResize)

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const bannerStyle = !isMobile
    ? {
        backgroundImage: `url(${bannerImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: 'auto',
        width: '100vw',
      }
    : {
        background: '#b20000',
      }

  return (
    <div
      className="contact-separator min-h-[190px] lg:min-h-[230px] xl:min-h-[250px] 2xl:min-h-[290px] h-auto flex items-center justify-center"
      style={bannerStyle}
    >
      <div className="container-normal w-full">
        <div className="flex gap-x-4 w-full flex-col lg:flex-row justify-center items-center py-10 lg:py-0">
          <div className="left w-full md:w-1/2 text-center lg:text-left">
            <h3 className="text-white font-bold leading-[2.5rem] text-3xl md:text-4xl lg:text-4xl xl:text-5xl lg:leading-[3.5rem] mb-8 lg:mb-0">
              Do you have more <br />
              questions? Call us
            </h3>
          </div>
          <div className="right w-1/2 flex items-center justify-center lg:justify-end">
            <button
              className="bg-gray-900 text-white font-bold text-xl lg:text-3xl py-4 px-8 rounded-full hover:bg-red-900 transition"
              aria-label="Zadzwoń teraz"
            >
              Tel. +48 453 444 334
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactSeparator

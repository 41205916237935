import { GiCartwheel } from 'react-icons/gi'
import bannerImage from '../images/banner-image.jpg'

const Hero = () => {
  const bannerStyle = {
    backgroundImage: `url(${bannerImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    width: '100vw',
  }

  const dotStyle = {
    width: '7.5px',
    height: '2px',
    backgroundColor: '#d10000', // Kolor kropek
    borderRadius: '2px',
    marginRight: '2px', // Odstęp między kropkami
  }

  const createDots = (count) => {
    return Array.from({ length: count }, (_, index) => (
      <span key={index} style={dotStyle}></span>
    ))
  }

  return (
    <div
      className="banner bg-gray-800 h-screen w-screen flex"
      style={bannerStyle}
    >
      <div className="container-normal">
        <div className="flex justify-center items-center flex-col w-screen h-screen text-white">
          <div className="wheel relative flex items-center mb-4">
            <GiCartwheel className="bg-red-600 text-6xl text-white rounded-full p-1 mx-2 z-0" />
            <div
              className="absolute left-[85px] flex items-center top-1/2 -translate-y-1/2"
              style={{ transform: 'translateX(-10px)' }}
            >
              {createDots(10)}
            </div>
            <div
              className="absolute right-[84px] flex items-center top-1/2 -translate-y-1/2"
              style={{ transform: 'translateX(10px)' }}
            >
              {createDots(10)}
            </div>
          </div>
          <h1 className="text-[3rem] leading-[3.5rem] font-bold text-center sm:text-[3.5rem] sm:leading-[4.2rem] md:text-[4.2rem] md:leading-[4.9rem] lg:text-[4.8rem] lg:leading-[5.5rem]">
            Drive Clean, Stay Safe
            <br />
            <span className="text-[2.4rem] leading-[3rem] sm:text-[2.8rem] sm:leading-[3.5rem] md:text-3.2rem] md:leading-[3.9rem] lg:text-[3.6rem] lg:leading-[4.3rem]">
              Choose ExSpray Mudflaps!
            </span>
            <div className="flex flex-col justify-center gap-5 mt-10 sm:mt-5 sm:flex-row">
              <div className="red-button relative flex items-center justify-center my:2 sm:my-8">
                <a
                  href="#about-us"
                  className="bg-red-600 rounded-full px-7 hover:bg-red-700 duration-200"
                >
                  <p className="relative text-base text-white font-semibold px-5 py-4 flex items-center justify-center before:content-[''] before:absolute before:top-1/2 before:h-[2px] before:bg-white before:w-[10px] before:-translate-y-1/2 after:content-[''] after:absolute after:top-1/2 after:h-[2px] after:bg-white after:w-[10px] after:-translate-y-1/2 before:left-0 after:right-0">
                    Read More
                  </p>
                </a>
              </div>
              <div className="white-button relative flex items-center justify-center my:2 sm:my-8">
                <a
                  href="#our-products"
                  className="bg-white rounded-full px-7 hover:bg-gray-100 duration-200"
                >
                  <p className="relative text-base text-black font-semibold px-5 py-4 flex items-center justify-center before:content-[''] before:absolute before:top-1/2 before:h-[2px] before:bg-black before:w-[10px] before:-translate-y-1/2 after:content-[''] after:absolute after:top-1/2 after:h-[2px] after:bg-black after:w-[10px] after:-translate-y-1/2 before:left-0 after:right-0">
                    Our Products
                  </p>
                </a>
              </div>
            </div>
          </h1>
        </div>
      </div>
    </div>
  )
}

export default Hero

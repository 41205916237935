import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import 'swiper/css'
import 'swiper/css/navigation'
import { Navigation } from 'swiper/modules'
import SectionTitleWithDots from './SectionTitleWithDots'

import logo1 from '../images/partners/1-stedall.png'
import logo2 from '../images/partners/2-safholland.png'
import logo3 from '../images/partners/3-broshuis.png'
import logo4 from '../images/partners/4-matro.png'
import logo5 from '../images/partners/5-fritsdijk.png'
import logo6 from '../images/partners/6-sure.webp'
import logo7 from '../images/partners/7-knott.png'
import logo8 from '../images/partners/8-truckjunkie.png'
import logo9 from '../images/partners/9-bax.png'
import logo10 from '../images/partners/10-go-in-style.png'
import logo11 from '../images/partners/11-SCB-logo.png'
import logo12 from '../images/partners/12-branding.png'
import logo13 from '../images/partners/13-atlrenting.png'
import logo14 from '../images/partners/14-gs_logo_blue.png'
import logo15 from '../images/partners/15-pacton.jpg'
import logo16 from '../images/partners/16-pronar.png'

const logos = [
  { id: 1, link: 'https://stedall.co.uk/', image: logo1 },
  { id: 2, link: 'https://safholland.com/cz/en/', image: logo2 },
  { id: 3, link: 'https://www.broshuis.com/', image: logo3 },
  { id: 4, link: 'https://matro.be/', image: logo4 },
  { id: 5, link: 'https://fritsdijk.nl/', image: logo5 },
  { id: 6, link: 'https://www.suer.de/', image: logo6 },
  { id: 7, link: 'https://www.knott.de/', image: logo7 },
  { id: 8, link: 'https://www.truckjunkie.com/', image: logo8 },
  { id: 9, link: 'https://sklep.bax-lak.pl/', image: logo9 },
  {
    id: 10,
    link: 'https://www.go-in-style.nl/en/tags/gis-holland/',
    image: logo10,
  },
  { id: 11, link: 'https://www.cargobull.com/en', image: logo11 },
  { id: 12, link: 'https://www.ftg-fahrzeugteile.de/', image: logo12 },
  { id: 13, link: 'https://www.atlrenting.be/en', image: logo13 },
  { id: 14, link: 'https://www.gsmeppel.nl/', image: logo14 },
  { id: 15, link: 'https://www.pacton.nl/en/', image: logo15 },
  { id: 16, link: 'https://pronar.pl/', image: logo16 },
]

const WeCooperate = () => {
  return (
    <div className="w-full h-full bg-gray-100 px-4 md:px-14 pt-10 pb-16 sm:px-0">
      <div id="about-us" className="partners container-normal lg:mb-2">
        <SectionTitleWithDots titleText="Trusted us" color="#eaeaea" />
      </div>
      <section className="container-normal text-gray-600 body-font mt-[-35px]">
        <div className="w-full px-5 pt-5 mx-auto flex flex-col justify-center items-center">
          <h2 className="text-center font-bold text-red-600 text-sm uppercase mb-4">
            We Cooperate with Trusted Partners{' '}
          </h2>
          <p className="text-basic text-black mb-10 text-center w-2/3">
            We are proud to collaborate with a diverse group of trusted partners
            who play a crucial role in helping us deliver top-quality solutions.
            Below is a selection of our key partners, whose expertise and
            ongoing support empower us to grow and innovate consistently.
          </p>

          <div className="relative w-full mx-auto">
            <style>{`
              .swiper-button-prev::after{
                width: 0;
                content: '';
              }
              .swiper-button-prev,
              .swiper-button-next {
                left: -35px; /* Zmiana wartości z 10px na -35px */
                right: auto;
                width: auto; /* Ustawienie szerokości na auto */
                height: auto; /* Ustawienie wysokości na auto */
                color: white; /* Ustawienie koloru strzałek na biały */
              }

              .swiper-button-next::after {
                width: 0;
                content: '';
              }
              .swiper-button-next {
                left: auto; /* Zmiana wartości z 10px na -35px */
                right: -35px;
                width: auto; /* Ustawienie szerokości na auto */
                height: auto; /* Ustawienie wysokości na auto */
                color: white; /* Ustawienie koloru strzałek na biały */
              }
              .swiper-wrapper {
                align-items: center !important;
              }
            `}</style>

            <Swiper
              modules={[Navigation]}
              spaceBetween={0}
              slidesPerView={1} // Domyślna liczba slajdów
              loop={true}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2, // Wyświetlanie 1 slajdu na mniejszych ekranach (np. telefony)
                },
                768: {
                  slidesPerView: 3, // Wyświetlanie 2 slajdów na tabletach
                },
                1024: {
                  slidesPerView: 4, // Wyświetlanie 3 slajdów na większych ekranach
                },
                1280: {
                  slidesPerView: 5, // Wyświetlanie 4 slajdów na dużych ekranach
                },
              }}
            >
              {logos.map((logo, index) => (
                <SwiperSlide
                  key={logo.id}
                  className="flex justify-center items-center py-4 px-8"
                >
                  <a href={logo.link} rel="nofollow">
                    <img
                      src={logo.image}
                      alt={`Logo ${logo.id}`}
                      className="w-full h-auto max-h-20 object-contain"
                    />
                  </a>
                </SwiperSlide>
              ))}
            </Swiper>

            {/* Przycisk do przewijania do przodu */}
            <button
              className="swiper-button-next absolute left-[-55px] p-2 bg-red-600 text-white rounded-full w-10 h-10 flex justify-center items-center "
              aria-label="Przewiń do przodu"
            >
              <MdKeyboardArrowRight className="text-xl" />
            </button>

            {/* Przycisk do cofania */}
            <button
              className="swiper-button-prev absolute right-[-55px] p-2 bg-red-600 text-white rounded-full w-10 h-10 flex justify-center items-center"
              aria-label="Przewiń do tyłu"
            >
              <MdKeyboardArrowLeft className="text-xl" />
            </button>
          </div>
        </div>
      </section>
    </div>
  )
}

export default WeCooperate

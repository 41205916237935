import React, { useRef, useEffect, useCallback } from 'react'
import { FaCheck } from 'react-icons/fa'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'

// import ExSprayMudflapThumb from '../images/ExSprayMudflapThumb.jpg'
// import KSMudflapThumb from '../images/KSMudflapThumb.jpg'
// import DifferentSizesAndShapeThumb from '../images/DifferentSizesAndShapeThumb.jpg'
// import BackSideMudflapThumb from '../images/BackSideMudflapThumb.jpg'

import BgStyle from '../images/slide-bg.jpg'
// KS Mudflap
import ks1 from '../images/products/KS Mudflap/KS-1.jpg'
import ks2 from '../images/products/KS Mudflap/KS-2.jpg'
import ks3 from '../images/products/KS Mudflap/KS-3.jpg'
import ks4 from '../images/products/KS Mudflap/KS-4.jpg'
// Different Sizes And Shape
import ds1 from '../images/products/Different Sizes And Shape/ds1.jpg'
import ds2 from '../images/products/Different Sizes And Shape/ds2.jpg'
import ds3 from '../images/products/Different Sizes And Shape/ds3.jpg'
import ds4 from '../images/products/Different Sizes And Shape/ds4.jpg'
// ExSpray Mudflap
import exmud1 from '../images/products/ExSpray Mudflap/exmud1.jpg'
import exmud2 from '../images/products/ExSpray Mudflap/exmud2.jpg'
import exmud3 from '../images/products/ExSpray Mudflap/exmud3.jpg'
import exmud4 from '../images/products/ExSpray Mudflap/exmud4.jpg'
import exmud5 from '../images/products/ExSpray Mudflap/exmud5.jpg'
// ExSpray & KS Graphics
import ksgraphic1 from '../images/products/ExSpray & KS Graphics/ks-graphic-1.jpg'
import ksgraphic2 from '../images/products/ExSpray & KS Graphics/ks-graphic-2.jpg'
import ksgraphic3 from '../images/products/ExSpray & KS Graphics/ks-graphic-3.jpg'
import ksgraphic4 from '../images/products/ExSpray & KS Graphics/ks-graphic-4.jpg'
import ksgraphic5 from '../images/products/ExSpray & KS Graphics/ks-graphic-5.jpg'

const products = [
  {
    id: 1,
    title: 'KS<br/>Mudflap',
    description:
      'KS mud flaps are designed with durability and high performance in mind, tailored to meet a variety of operational needs. Our product stands out for its robust construction and reliability in various conditions. The difference between KS and ExSpray is the absence of water-absorbing bristles.',
    features: [
      'Durable Construction',
      'High Performance',
      'Tailored for Various Operational Needs',
      'Reliable in Diverse Conditions',
      'No Water-Absorbing Bristles',
    ],
    images: [ks1, ks2, ks3, ks4],
    thumbnail: ks1,
  },
  {
    id: 2,
    title: 'Different Sizes<br/>And Shape',
    description:
      'Our product line, available in various sizes and shapes, offers a wide range of dimensions and configurations to meet different requirements. Designed with flexibility and adaptability in mind for various environments. During the production of mud flaps, we have provided our customers with over 1000 solutions.',
    features: [
      'Variety of Sizes and Shapes',
      'Flexible Configurations',
      'Adaptable Design',
      'Suitable for Various Requirements',
      'Over 1000 Custom Solutions',
    ],
    images: [ds1, ds2, ds3, ds4],
    thumbnail: ds1,
  },
  {
    id: 3,
    title: 'ExSpray<br/>Mudflap',
    description:
      'ExSpray is a leader in advanced spray reduction systems, designed to meet the highest safety and efficiency standards. We offer innovative solutions that exceed the expectations of customers worldwide.',
    features: [
      'Technological Edge',
      'Regulatory Compliance',
      'Personalized Solutions',
      'Reliability',
      'Fast Delivery',
    ],
    images: [exmud1, exmud2, exmud3, exmud4, exmud5],
    thumbnail: exmud1,
  },
  {
    id: 4,
    title: 'ExSpray & <br/>KS Graphics',
    description:
      'Our colorful graphics on mudflaps guarantee incredible durability and resistance to weather conditions, ensuring that your company’s logo remains vivid and long-lasting for many years.',
    features: [
      'Incredible Durability',
      'Weather Resistance',
      'Vivid and Long-Lasting Graphics',
      'High-Quality Materials',
      'Perfect for Long-Term Use',
    ],
    images: [ksgraphic1, ksgraphic2, ksgraphic3, ksgraphic4, ksgraphic5],
    thumbnail: ksgraphic1,
  },
]

const ProductSlider = () => {
  const [activeProduct, setActiveProduct] = React.useState(products[0])
  const [activeImageIndex, setActiveImageIndex] = React.useState(0)
  const sliderRef = useRef(null)
  const intervalRef = useRef(null)

  const handleNextImage = useCallback(() => {
    setActiveImageIndex((prev) => (prev + 1) % activeProduct.images.length)

    // Resetuj interwał po kliknięciu
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
    intervalRef.current = setInterval(() => {
      setActiveImageIndex((prev) => (prev + 1) % activeProduct.images.length)
    }, 2000)
  }, [activeProduct.images.length])

  const handlePrevImage = useCallback(() => {
    setActiveImageIndex(
      (prev) =>
        (prev - 1 + activeProduct.images.length) % activeProduct.images.length
    )

    // Resetuj interwał po kliknięciu
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
    intervalRef.current = setInterval(() => {
      setActiveImageIndex((prev) => (prev + 1) % activeProduct.images.length)
    }, 2000)
  }, [activeProduct.images.length])

  const scrollToSlider = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleProductClick = (product) => {
    setActiveProduct(product)
    setActiveImageIndex(0)
    scrollToSlider()

    // Resetuj interwał przy kliknięciu produktu
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }

    intervalRef.current = setInterval(handleNextImage, 2000)
  }

  useEffect(() => {
    // Rozpocznij interwał przy załadowaniu komponentu
    intervalRef.current = setInterval(handleNextImage, 2000)

    return () => {
      // Wyczyść interwał przy odmontowywaniu komponentu
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [activeProduct, handlePrevImage, handleNextImage])

  const bgStyle = {
    backgroundImage: `url(${BgStyle})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat',
    height: '100%',
    width: '100%',
  }

  return (
    <div
      id="our-products"
      className="slider bg-black py-20 text-white h-full w-full"
      style={bgStyle}
    >
      <div className="container-normal flex-col">
        {/* Subtitle */}
        <div className="text-center font-bold text-red-600 text-sm uppercase mb-4">
          Our Products
        </div>

        {/* Title */}
        <div className="text-center text-3xl md:text-5xl font-bold mb-0">
          Explore our products
        </div>

        {/* Slider */}
        <div
          ref={sliderRef}
          className="relative mt-20 w-full mx-auto min-h-[450px] flex flex-col md:flex-row items-scretch bg-white"
        >
          {/* Sekcja lewa - tekst */}
          <div className="w-full pl-10 pr-6 py-8 text-black flex-col md:w-1/2 xl:w-1/3">
            <h3 className="text-red-600 text-4xl mb-4 font-semibold capitalize">
              {activeProduct.title.replace(/<br\s*\/?>/gi, ' ')}
            </h3>

            <p className="text-base mb-4">{activeProduct.description}</p>

            <ul className="space-y-2 mb-10 text-base">
              {activeProduct.features.map((feature, index) => (
                <li key={index} className="relative pl-8 pb-1">
                  <span className="absolute left-0 top-1/2 transform -translate-y-1/2 text-green-500">
                    <FaCheck className="text-lg" />
                  </span>
                  {feature}
                </li>
              ))}
            </ul>

            <button
              className="bg-red-600 py-4 px-8 rounded-full text-white font-semibold"
              aria-label="zadzwoń teraz"
            >
              Tel. +48 453 444 334
            </button>
          </div>

          {/* Sekcja prawa - slider zdjęć */}
          <div className="w-full md:w-1/2 xl:w-2/3 md:block relative">
            {/* Strzałka w lewo */}
            <button
              className="absolute top-1/2 left-[20px] p-2 bg-red-600 text-white rounded-full w-10 h-10 flex justify-center items-center transform -translate-y-1/2"
              onClick={handlePrevImage}
              aria-label="Przewiń do tyłu"
            >
              <MdKeyboardArrowLeft className="text-xl" />
            </button>

            <img
              src={activeProduct.images[activeImageIndex]}
              alt={activeProduct.title}
              className="h-full object-cover w-full"
            />

            {/* Strzałka w prawo */}
            <button
              className="absolute top-1/2 right-[20px] p-2 bg-red-600 text-white rounded-full w-10 h-10 flex justify-center items-center transform -translate-y-1/2"
              onClick={handleNextImage}
              aria-label="Przewiń do przodu"
            >
              <MdKeyboardArrowRight className="text-xl" />
            </button>

            {/* Kropki na zdjęciu */}
            <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2">
              {activeProduct.images.map((_, index) => (
                <div
                  key={index}
                  className={`w-3 h-3 rounded-full cursor-pointer ${
                    index === activeImageIndex ? 'bg-red-600' : 'bg-gray-400'
                  }`}
                  onClick={() => {
                    setActiveImageIndex(index)

                    // Resetuj interwał po kliknięciu w kropkę
                    if (intervalRef.current) {
                      clearInterval(intervalRef.current)
                    }
                    intervalRef.current = setInterval(() => {
                      setActiveImageIndex(
                        (prev) => (prev + 1) % activeProduct.images.length
                      )
                    }, 2000)
                  }}
                />
              ))}
            </div>
          </div>
        </div>

        {/* Lista produktów */}
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8 mt-8">
          {products.map((product) => (
            <div
              key={product.id}
              className="bg-[#26272C] cursor-pointer hover:scale-105 transition-transform duration-300"
              onClick={() => handleProductClick(product)}
            >
              <img
                src={product.thumbnail}
                alt={product.title}
                className="w-full h-[250px] object-cover border-4 border-red-700"
              />
              <h3
                dangerouslySetInnerHTML={{ __html: product.title }}
                className="text-center text-3xl leading-10 font-semibold py-4 px-1 bg-gray-800"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProductSlider

import SectionTitleWithDots from './SectionTitleWithDots'
import ExSprayImgLeftIconBoxes from '../images/ExSprayImgLeftIconBoxes.jpg'
import { FaHeart, FaStar, FaCogs, FaLeaf } from 'react-icons/fa' // Ikony z react-icons

// Obiekt zawierający wszystkie dane dla sekcji
const sectionData = {
  image: ExSprayImgLeftIconBoxes || 'https://via.placeholder.com/500x400',
  header:
    "Discover the dedication and craftsmanship behind ExSpray's mudflaps.",
  introText: (
    <>
      <p>
        ExSpray is a leading manufacturer of high-quality mudflaps for trucks.
        For over 25 years, our goal has been to provide durable and reliable
        products that meet the highest customer standards.
      </p>
      <br />
      <p>
        Since 2024, ExSpray has evolved from a small production department into
        a modern manufacturing facility located in Kraśnica-Kolonia, Poland. Our
        commitment to innovation and excellence has earned us a reputation as a
        trusted partner for over 100 clients across Europe.
      </p>
      <br />
      <p>
        At ExSpray, we prioritize quality and precision. Our mudflaps are
        designed to enhance your safety in challenging road conditions. We use
        only the best materials and the latest manufacturing techniques to
        deliver products that meet the expectations of the most demanding
        customers.
      </p>
      <br />
      <p>
        Our team consists of qualified specialists and enthusiasts. Everything
        we do, from design to production to quality control, is driven by the
        pursuit of excellence.
      </p>
    </>
  ),
  iconBoxes: [
    {
      icon: <FaHeart />,
      title: 'Reliable Guarantee',
      description: 'The only system with a 2-year written warranty.',
    },
    {
      icon: <FaStar />,
      title: 'Easy Installation',
      description: 'Fitting for every type of trailer with online support.',
    },
    {
      icon: <FaCogs />,
      title: 'Strict Standards',
      description: 'ExSpray exceeds European spray reduction regulations.',
    },
    {
      icon: <FaLeaf />,
      title: 'Innovative Design',
      description:
        'Customization of shapes and logos according to customer requirements.',
    },
  ],
}

const IconBox = ({ icon, title, description }) => {
  return (
    <div className="item flex items-center pb-4">
      {/* Ikona */}
      <div className="icon-area mr-4 text-red-600 text-4xl 2xl:text-6xl">
        {icon}
      </div>
      {/* Treść */}
      <div className="text-area">
        <h3 className="text-lg md:text-lg font-bold uppercase">{title}</h3>
        <p className="text-sm font-semibold">{description}</p>
      </div>
    </div>
  )
}

const FeaturesWithImageSection = () => {
  return (
    <>
      <div id="about-us" className="about-us container-normal lg:mb-2">
        <SectionTitleWithDots titleText="About Us" color="#f3f4f6" />
      </div>
      {/* Sekcja dla breakpointa 2xl i większych */}
      <section className="container-full hidden 2xl:block w-full mx-auto bg-gray-100 mb-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Lewa kolumna - obraz */}
          <div className="w-[75%] h-auto">
            <img
              src={sectionData.image}
              alt="Opis zdjęcia"
              className="h-full max-h-[auto] w-full object-cover"
            />
          </div>

          {/* Prawa kolumna - treść */}
          <div className="w-[95%] pl-0 ml-[-22.5%] py-10 flex flex-col justify-center items-start">
            {/* Nagłówek */}
            <h2
              className="text-4xl font-bold mb-4"
              dangerouslySetInnerHTML={{ __html: sectionData.header }}
            />
            {/* Tekst wprowadzający */}
            <p className="text-basic text-gray-700 mb-10">
              {sectionData.introText}
            </p>

            {/* Boxy z ikonami */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {sectionData.iconBoxes.map((box, index) => (
                <IconBox
                  key={index}
                  icon={box.icon}
                  title={box.title}
                  description={box.description}
                />
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Sekcja poniżej breakpointa 2xl */}
      <section className="features-image-section 2xl:hidden flex flex-col container-normal bg-gray-100 mb-16 py-8">
        {/* Układ poniżej 2xl */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 container w-full mx-auto">
          {/* Lewa kolumna - obraz */}
          <div className="image-area w-full h-auto">
            <img
              src={sectionData.image}
              alt="Opis zdjęcia"
              className="h-full max-h-[auto] w-full object-cover"
            />
          </div>

          {/* Prawa kolumna - treść */}
          <div className="text-area w-full flex flex-col justify-center items-start xs:pl-4">
            {/* Nagłówek */}
            <h2
              className="text-2xl lg:text-4xl font-bold mb-4"
              dangerouslySetInnerHTML={{ __html: sectionData.header }}
            />
            {/* Tekst wprowadzający */}
            <p className="text-base lg:text-lg text-gray-700 mb-6">
              {sectionData.introText}
            </p>
          </div>
        </div>

        {/* Boxy z ikonami - wyświetlane poniżej tekstu dla sm - lg */}
        <div className="items-with-icons-area grid grid-cols-1 md:grid-cols-2 gap-4 container w-full mx-auto px-4 lg:px-0 mt-8">
          {sectionData.iconBoxes.map((box, index) => (
            <IconBox
              key={index}
              icon={box.icon}
              title={box.title}
              description={box.description}
            />
          ))}
        </div>
      </section>
    </>
  )
}

export default FeaturesWithImageSection

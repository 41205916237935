import React from 'react'
import { IoWaterOutline } from 'react-icons/io5'
import { PiResizeLight } from 'react-icons/pi'
import { IoShieldCheckmarkOutline } from 'react-icons/io5'
import { FaRegLightbulb } from 'react-icons/fa6'
import bannerImage from '../images/bg-products-info.jpg'

const ProductCards = () => {
  const cards = [
    {
      id: 1,
      icon: <IoWaterOutline className="text-6xl text-red-600" />,
      title: 'Advanced <br />Splash Reduction',
      text: 'Discover how our ExSpray system enhances safety on the road.',
      hoverText:
        'The use of the ExSpray system in our mud flaps allows for the absorption of 80% of the water from the wheels, which improves visibility and safety on the road. This product is fully certified and meets the standards set by the European Commission.',
    },
    {
      id: 2,
      icon: <PiResizeLight className="text-6xl text-red-600" />,
      title: 'Custom <br />Fit Solutions',
      text: "Explore our tailored solutions for every customer's needs",
      hoverText:
        "Custom Fit Solutions. We respond to all individual customer needs. In addition to standard mudflaps, we can create any shape that meets the customer's requirements",
    },
    {
      id: 3,
      icon: <IoShieldCheckmarkOutline className="text-6xl text-red-600" />,
      title: 'High-Quality <br/>Material',
      text: 'Discover the superior materials that ensure durability in extreme conditions',
      hoverText:
        'High-Quality Material. The material used for our mudflaps features a three-layer reinforced structure, ensuring high resistance to physical and atmospheric factors. The mudflaps are designed to work in extreme temperatures, from -25°C to 70°C',
    },
    {
      id: 4,
      icon: <FaRegLightbulb className="text-6xl text-red-600" />,
      title: 'Branding <br />Opportunities',
      text: 'Unlock branding potential with our customizable mudflaps',
      hoverText:
        'Branding Opportunities. Although the primary purpose of ExSpray and KS splash guards is to catch water from vehicle wheels, the outer surface of our mudflaps provides an excellent space for placing advertisements, logos, etc., in single or multiple colors',
    },
  ]

  return (
    <div className="product-cards mt-[-50px] container-normal mx-auto p-4">
      <div className="flex flex-wrap gap-5 justify-center w-full">
        {cards.map((card) => (
          <div
            key={card.id}
            className="group relative flex flex-col items-center justify-center p-8 w-full sm:w-[calc(50%-1rem)] lg:w-[calc(25%-1rem)] h-[285px] bg-white border border-gray-200 border-t-4 hover:border-t-0 border-l-0 border-r-0 border-b-0 border-t-red-600 shadow-lg transition-all duration-300 ease-in-out"
          >
            {/* Icon, Title and Description */}
            <div className="flex flex-col items-center justify-center text-center transition-opacity duration-300 group-hover:opacity-0">
              {card.icon}
              <h2
                className="mt-4 text-xl font-bold"
                dangerouslySetInnerHTML={{ __html: card.title }}
              ></h2>
              <p className="mt-2 text-base text-gray-600">{card.text}</p>
            </div>

            {/* Long Description on Hover */}
            <div
              className="absolute inset-0 flex flex-col items-center justify-center p-8 text-white text-base font-semibold opacity-0 group-hover:opacity-100 transition-opacity duration-300 cursor-pointer bg-red-600 group-hover:bg-red-600"
              style={{
                backgroundImage: `url(${bannerImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <h2
                className="text-lg font-bold mb-1 text-center"
                dangerouslySetInnerHTML={{ __html: card.title }}
              ></h2>
              <p className="text-center text-sm">{card.hoverText}</p>
              {/* White line */}
              <div
                className="mt-4"
                style={{
                  height: '2px',
                  width: '40px',
                  backgroundColor: 'white',
                }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProductCards

import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa'
import { BsArrowRight } from 'react-icons/bs'
import logo from '../images/logo.png'

const Footer = () => {
  return (
    <footer className="bg-[#0d0e14] text-white">
      <div className="container-normal pt-20 pb-10">
        <div className="px-6 flex w-full flex-col md:flex-row">
          {/* Pierwsza sekcja */}
          <div className="flex flex-col w-full md:w-2/4 mb-6 md:mb-0">
            <div className="logo flex items-center mb-5 relative">
              <img
                src={logo}
                alt="logo"
                className="w-[150px] h-auto object-contain"
              />
            </div>

            <p className="mb-6 text-gray-500">
              Polish manufacturer of durable truck mudflaps <br />
              of experience, serving over 100 European clients <br />
              and focusing on innovation, quality, and safety.
            </p>
            <p className="text-white font-bold mb-2">Working hours</p>
            <p className="text-gray-500">Mon-Fri (7.00 - 15.00)</p>
            <div className="flex space-x-4 mt-4">
              {/* Ikony mediów społecznościowych */}
              <a
                href="/"
                className="text-red-500 hover:text-red-400"
                aria-label="Przejdź na stronę facebook"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                href="/"
                className="text-red-500 hover:text-red-400"
                aria-label="Przejdź na stronę twitter"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="/"
                className="text-red-500 hover:text-red-400"
                aria-label="Przejdź na stronę instagram"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>

          {/* Druga sekcja */}
          <div className="w-full md:w-1/4 mb-6 md:mb-0">
            <p className="text-xl mb-2 font-bold">
              Links
              <div className="h-[2px] w-[30px] bg-red-600 mt-2 mb-4" />
            </p>
            <ul className="list-none space-y-3">
              <li>
                <a
                  href="#start"
                  className="scroll-smooth flex items-center text-gray-500 hover:text-red-500"
                >
                  <span className="mr-2">
                    <BsArrowRight />
                  </span>{' '}
                  Start
                </a>
              </li>
              <li>
                <a
                  href="#about-us"
                  className="scroll-smooth flex items-center text-gray-500 hover:text-red-500"
                >
                  <span className="mr-2">
                    <BsArrowRight />
                  </span>{' '}
                  About us{' '}
                </a>
              </li>
              <li>
                <a
                  href="#our-products"
                  className="scroll-smooth flex items-center text-gray-500 hover:text-red-500"
                >
                  <span className="mr-2">
                    <BsArrowRight />
                  </span>{' '}
                  Our products
                </a>
              </li>
              <li>
                <a
                  href="#contact"
                  className="scroll-smooth flex items-center text-gray-500 hover:text-red-500"
                >
                  <span className="mr-2">
                    <BsArrowRight />
                  </span>{' '}
                  Contact
                </a>
              </li>
            </ul>
          </div>

          {/* Trzecia sekcja */}
          <div className="w-full md:w-1/4">
            <p className="text-xl mb-4 font-bold">
              Contact
              <div className="h-[2px] w-[30px] bg-red-600 mt-2" />
            </p>
            <div className="space-y-4">
              <div className="flex flex-col items-start">
                <p className="font-bold mb-2">Telephone Number:</p>
                <div className="flex justify-center items-center">
                  <FaPhone className="bg-red-600 p-2 text-white mr-2 text-3xl rounded-full" />
                  <span className="text-gray-500"> +48 453 444 334</span>
                </div>
              </div>

              <div className="flex flex-col items-start">
                <p className="font-bold mb-2">Email: </p>
                <div className="flex justify-center items-center">
                  <FaEnvelope className="bg-red-600 p-2 text-white mr-2 text-3xl rounded-full" />
                  <span className="text-gray-500">sales@exspray.eu</span>{' '}
                </div>
              </div>

              <div className="flex flex-col items-start">
                <p className="font-bold mb-2">Location: </p>
                <div className="flex justify-center items-center">
                  <FaMapMarkerAlt className="bg-red-600 p-2 text-white mr-2 text-3xl rounded-full" />
                  <span className="text-gray-500">
                    Kraśnica-Kolonia 73, <br />
                    62-590 Golina Poland
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sekcja z prawami autorskimi */}
      <div className="container-normal ">
        <div className="bg-red-600 text-center w-full justify-center py-6 mt-10">
          <p>
            Exspray © 2024 All Rights Reserved. Project Implementation{' '}
            <a className="hover:underline" href="https://webkon.eu/">
              Webkon
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
